const H_CLEAN_MODE = 'proxy'

const hClean = {
  prod: 'https://delivery.mxefw.com',
  dev: 'http://beta.clean.qingyunxinxi.com',
  proxy: ''
}


export default {
  hClean: hClean[H_CLEAN_MODE],
}
