const error = {
  401: '您还没有登录',
  403: '权限不足，禁止访问',
  429: '请求次数过多',
  404: '页面找不到了',
}

export function getRequestErrorTips(status:number) {
  // @ts-ignore
  return error.hasOwnProperty(status) ? error[status] : '网络请求错误:' + status
}

